import { Vue, Component, Ref } from 'vue-property-decorator';

@Component({
  metaInfo: {
    title: 'Prospectus',
  },
})

export default class Prospectus extends Vue {
  @Ref('download') readonly download!: HTMLAnchorElement;

  mounted(): void {
    this.download.click();
  }
}
